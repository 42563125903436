import React from 'react';
import PropTypes from 'prop-types';
import Script from 'next/script';
import { ENVIRONMENT } from '../../../utils/constants';

const fundraiseUpAccountId = 'AGSTXLKM';

/**
 * Injects Fundraise Up script.
 */
const FundraiseUp = ({ livePaymentInDevEnv = false }) => (
  <>
    <Script id="fundraise-up">
      {`
(function(w,d,s,n,a){if(!w[n]){var l='call,catch,on,once,set,then,track'
.split(','),i,o=function(n){return'function'==typeof n?o.l.push([arguments])&&o
:function(){return o.l.push([n,arguments])&&o}},t=d.getElementsByTagName(s)[0],
j=d.createElement(s);j.async=!0;j.src='https://cdn.fundraiseup.com/widget/'+a;
t.parentNode.insertBefore(j,t);o.s=Date.now();o.v=4;o.h=w.location.href;o.l=[];
for(i=0;i<7;i++)o[l[i]]=o(l[i]);w[n]=o}
})(window,document,'script','FundraiseUp','${fundraiseUpAccountId}');
    `}
    </Script>
    {ENVIRONMENT !== 'production' && !livePaymentInDevEnv && (
      <Script id="fundraise-up--test-mode" strategy="beforeInteractive">
        {`window.fundraiseup_livemode = false;`}
      </Script>
    )}
  </>
);

FundraiseUp.propTypes = {
  livePaymentInDevEnv: PropTypes.bool,
};

export default FundraiseUp;
