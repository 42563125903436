import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';

const CookieBot = ({ cookiebotId }) => (
  <Head>
    <script
      dangerouslySetInnerHTML={{
        __html: `(function() {
          // Define Cookiebot variable to avoid the exception "Uncaught ReferenceError: Cookiebot
          // is not defined" for the cases when cookiebot script wasn't loaded yet, but components
          // used Cookiebot variable are rendered.
          window.Cookiebot = undefined;
        
          const scriptElem = document.createElement('script');
      
          scriptElem.setAttribute('src', 'https://consent.cookiebot.com/uc.js?cbid=${cookiebotId}');
          scriptElem.setAttribute('type', 'text/javascript');
          scriptElem.setAttribute('id', 'Cookiebot');
          scriptElem.setAttribute('async', '');
      
          document.head.insertBefore(scriptElem, document.head.firstChild);
        })();`,
      }}
    />
    {/** Configure default Google Consent by default
     to block GTM analytics before consent is granted.
     See @link https://support.cookiebot.com/hc/en-us/articles/360016047000-Cookiebot-and-Google-Consent-Mode
     */}
    <script
      data-cookieconsent="ignore"
      dangerouslySetInnerHTML={{
        __html: `window.dataLayer = window.dataLayer || [];
          function gtag() {
              dataLayer.push(arguments);
          }
          gtag("consent", "default", {
              ad_personalization: "denied",
              ad_storage: "denied",
              ad_user_data: "denied",
              analytics_storage: "denied",
              functionality_storage: "denied",
              personalization_storage: "denied",
              security_storage: "granted",
              wait_for_update: 500,
          });
          gtag("set", "ads_data_redaction", true);
          gtag("set", "url_passthrough", true);
        `,
      }}
    />
  </Head>
);

CookieBot.propTypes = {
  cookiebotId: PropTypes.string.isRequired,
};

export default CookieBot;
