import { useState, useEffect } from 'react';
import keyboardFocusWithinElement from '../../../utils/keyboardFocusWithinElement';

const KeepKeyboardFocusWithinCookiebotPopup = () => {
  const [visible, setVisible] = useState(false);

  const listenerFunction = () => {
    setVisible(true);
  };

  // Check if Cookiebot dialog is visible.
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const cookieBannerDisplay = document.getElementById('CB')?.style.display;
      if (cookieBannerDisplay === 'flex') {
        setVisible(true);
      } else {
        window.addEventListener('CookiebotOnDialogDisplay', listenerFunction);
      }
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('CookiebotOnDialogDisplay', listenerFunction);
      }
    };
  });

  const keepKeyboardFocus = (e) => {
    // Check if Cookie details are opened and define
    // focusable elements within Cookie popup.
    const details = document.getElementById('CookieBannerDetails');
    if (!details) {
      return;
    }

    const style = window.getComputedStyle(details);
    let wrapperId = '#CB';
    if (style.display === 'none') {
      wrapperId = '#CookieBannerMain';
    }

    keyboardFocusWithinElement(e, wrapperId);
  };

  useEffect(() => {
    if (visible) {
      window.addEventListener('keydown', keepKeyboardFocus);
    }
    return () => {
      if (visible) {
        window.removeEventListener('keydown', keepKeyboardFocus);
      }
    };
  });

  return null;
};

export default KeepKeyboardFocusWithinCookiebotPopup;
