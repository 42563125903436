import React from 'react';
import Head from 'next/head';
import gtmParts from 'react-google-tag-manager';
import PropTypes from 'prop-types';
import { DEFAULT_COUNTRY_CODE } from '../../../utils/constants';

const GoogleTagManager = ({
  gtmId,
  dataLayerName = 'dataLayer',
  additionalEvents = {},
  previewVariables = false,
}) => {
  const gtm = gtmParts({
    id: gtmId,
    dataLayerName,
    additionalEvents,
    previewVariables,
  });

  // Replace GTM domain to custom Server GTM domain to support Server GTM container.
  const gtmScript = gtm.scriptAsReact();
  gtmScript.props.dangerouslySetInnerHTML.__html =
    gtmScript.props.dangerouslySetInnerHTML.__html.replace(
      '//www.googletagmanager.com',
      DEFAULT_COUNTRY_CODE === 'IE' ? '//data.concern.net' : '//data.concern.org.uk',
    );

  // Insert originalLocation into dataLayer before GTM script.
  // See https://www.simoahava.com/gtm-tips/fix-rogue-referral-problem-single-page-sites/
  // Note that this code is always executed on server side.
  const originalLocationScript = `window.${dataLayerName} = window.${dataLayerName} || [];window.${dataLayerName}.push({originalLocation: document.location.protocol + "//" + document.location.hostname + document.location.pathname + document.location.search});`;

  if (typeof window !== 'undefined') {
    const offLineHandler = () => {
      // https://developers.google.com/tag-platform/security/guides/privacy#gtag.js_5
      window['ga-disable-' + gtmId] = true;
    };

    const onLineHandler = () => {
      delete window['ga-disable-' + gtmId];
    };

    window.addEventListener('online', onLineHandler);
    window.addEventListener('offline', offLineHandler);
  }

  return (
    <Head>
      {/* Currently, we can't use Script component from next js because to not break order of scripts in Head */}
      <script dangerouslySetInnerHTML={{ __html: originalLocationScript }} />
      {gtmScript}
    </Head>
  );
};

GoogleTagManager.propTypes = {
  gtmId: PropTypes.string.isRequired,
  dataLayerName: PropTypes.string,
  additionalEvents: PropTypes.shape(),
  previewVariables: PropTypes.string,
};

export default GoogleTagManager;
